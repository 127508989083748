import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';

// Images

import Structura from '../../../assets/images/structure_jba.png';

export default function StructuraJba() {
  const { t } = useTranslation();
  return (
    <div>
      <Navigation />

      <p style={{ textAlign: 'center', marginTop: '2rem' }}>{t('structura')}</p>
      <div
        style={{
          backgroundColor: '#15373B',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={Structura}
          alt="structura JBA"
          style={{ margin: '0 auto 1.5rem auto', width: '100%' }}
        />
      </div>
      {/*<div className="inside-page">*/}
      {/*  <div style={{ width: '80%' }} className="mr-auto ml-auto">*/}
      {/*    <p>{t('structura_text')}</p>*/}
      {/*    <p>{t('structura_text1')}</p>*/}
      {/*    <Accordion>*/}
      {/*      <Card>*/}
      {/*        <CardHeader>*/}
      {/*          <a*/}
      {/*            href="https://www.jbagroup.co.uk"*/}
      {/*            rel="noreferrer"*/}
      {/*            target="_blank"*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={group}*/}
      {/*              height="100px"*/}
      {/*              width="100px"*/}
      {/*              alt="JBA Group"*/}
      {/*            />*/}
      {/*          </a>*/}
      {/*          <Accordion.Toggle*/}
      {/*            as={Button}*/}
      {/*            variant="text"*/}
      {/*            eventKey="0"*/}
      {/*            style={{ textAlign: 'left' }}*/}
      {/*          >*/}
      {/*            <h3>JBA Group</h3>*/}
      {/*          </Accordion.Toggle>*/}
      {/*        </CardHeader>*/}
      {/*        <Accordion.Collapse eventKey="0">*/}
      {/*          <Card.Body>*/}
      {/*            <br />*/}
      {/*            {t('structura_1')}*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*            {t('structura_6')}*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*          </Card.Body>*/}
      {/*        </Accordion.Collapse>*/}
      {/*      </Card>*/}
      {/*    </Accordion>*/}
      {/*    <Accordion>*/}
      {/*      <Card>*/}
      {/*        <CardHeader>*/}
      {/*          <a*/}
      {/*            href="https://www.jbaconsulting.ie"*/}
      {/*            rel="noreferrer"*/}
      {/*            target="_blank"*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={consulting}*/}
      {/*              height="100px"*/}
      {/*              width="100px"*/}
      {/*              alt="JBA Group"*/}
      {/*            />*/}
      {/*          </a>*/}
      {/*          <Accordion.Toggle*/}
      {/*            as={Button}*/}
      {/*            variant="text"*/}
      {/*            eventKey="0"*/}
      {/*            style={{ textAlign: 'left' }}*/}
      {/*          >*/}
      {/*            <h3>JBA Consulting</h3>*/}
      {/*          </Accordion.Toggle>*/}
      {/*        </CardHeader>*/}
      {/*        <Accordion.Collapse eventKey="0">*/}
      {/*          <Card.Body>*/}
      {/*            <br />*/}
      {/*            {t('structura_2')} <br />*/}
      {/*            <br />*/}
      {/*            {t('structura_3')}*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*          </Card.Body>*/}
      {/*        </Accordion.Collapse>*/}
      {/*      </Card>*/}
      {/*    </Accordion>*/}
      {/*    <Accordion>*/}
      {/*      <Card>*/}
      {/*        <CardHeader style={{ display: 'inline-block' }}>*/}
      {/*          {*/}
      {/*            // TODO should be in the same line*/}
      {/*          }*/}
      {/*          <a*/}
      {/*            href="https://www.jbarisk.com"*/}
      {/*            rel="noreferrer"*/}
      {/*            target="_blank"*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={risk}*/}
      {/*              height="100px"*/}
      {/*              width="100px"*/}
      {/*              alt="JBA Group"*/}
      {/*            />*/}
      {/*          </a>*/}
      {/*          <Accordion.Toggle*/}
      {/*            as={Button}*/}
      {/*            variant="text"*/}
      {/*            eventKey="0"*/}
      {/*            style={{ textAlign: 'left' }}*/}
      {/*          >*/}
      {/*            <h3>JBA Risk Management</h3>*/}
      {/*          </Accordion.Toggle>*/}
      {/*        </CardHeader>*/}
      {/*        <Accordion.Collapse eventKey="0">*/}
      {/*          <Card.Body>*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*            {t('structura_4')}*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*          </Card.Body>*/}
      {/*        </Accordion.Collapse>*/}
      {/*      </Card>*/}
      {/*    </Accordion>*/}
      {/*    <Accordion>*/}
      {/*      <Card>*/}
      {/*        <CardHeader>*/}
      {/*          <a*/}
      {/*            href="https://www.jbatrust.org"*/}
      {/*            rel="noreferrer"*/}
      {/*            target="_blank"*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={trust}*/}
      {/*              height="100px"*/}
      {/*              width="100px"*/}
      {/*              alt="JBA Group"*/}
      {/*            />*/}
      {/*          </a>*/}
      {/*          <Accordion.Toggle*/}
      {/*            as={Button}*/}
      {/*            variant="text"*/}
      {/*            eventKey="0"*/}
      {/*            style={{ textAlign: 'left' }}*/}
      {/*          >*/}
      {/*            <h3>JBA Trust</h3>*/}
      {/*          </Accordion.Toggle>*/}
      {/*        </CardHeader>*/}
      {/*        <Accordion.Collapse eventKey="0">*/}
      {/*          <Card.Body>*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*            {t('structura_5')}*/}
      {/*            <br />*/}
      {/*            <br />*/}
      {/*          </Card.Body>*/}
      {/*        </Accordion.Collapse>*/}
      {/*      </Card>*/}
      {/*    </Accordion>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
